<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6">
        <v-select
          :label="$t('status')"
          :items="statusTypes"
          item-text="name"
          item-value="key"
          v-model="item.status"
          multiple
          chips
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-select
          :label="$t('priority')"
          :items="priorityTypes"
          item-text="name"
          item-value="key"
          v-model="item.priority"
          multiple
          chips
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          :label="$t('title')"
          type="text"
          v-model="item.title"
          clearable
        />
      </v-col>
      <v-col cols="6">
        <v-select
          v-if="backendUsers"
          :label="$t('AssignedTo')"
          :items="backendUsers"
          item-value="@id"
          v-model="item.assignedTo"
          clearable
        >
          <template slot="selection" slot-scope="data">
            <template v-if="data.item.profile">
              {{ getProfileItemBasics(data.item.profile, 'firstName') }}
              {{ getProfileItemBasics(data.item.profile, 'lastName') }}
            </template>
            <template v-else>
              {{ data.item.email }}
            </template>
          </template>
          <template slot="item" slot-scope="data">
            <v-list-item-content>
              <v-list-item-title>
                <template v-if="data.item.profile">
                  {{ getProfileItemBasics(data.item.profile, 'firstName') }}
                  {{ getProfileItemBasics(data.item.profile, 'lastName') }}
                </template>
                <template v-else>
                  {{ data.item.email }}
                </template>
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {mapActions} from "vuex";
import ProfileMixin from "@/mixins/ProfileMixin";

export default {
  name: "IssueFilter",
  mixins: [ProfileMixin],
  props: {
    values: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      statusTypes: [
        {
          key: 'new',
          name: this.$t("New")
        },
        {
          key: 'inProgress',
          name: this.$t("In progress")
        },
        {
          key: 'feedback',
          name: this.$t("Feedback")
        },

        {
          key: 'done',
          name: this.$t("Done")
        },
      ],
      priorityTypes: [
        {name: this.$t("High"), key: 100},
        {name: this.$t("Medium"), key: 50},
        {name: this.$t("Low"), key: 10},
      ],
    }
      ;
  },
  mounted() {
    this.backendUsersGetSelectedItems();
  },
  computed: {
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },
    ...mapFields("backendUser", {
      backendUsers: "selectItems",
    }),
  },
  methods: {
    ...mapActions({
      backendUsersGetSelectedItems: "backendUser/fetchSelectItems",
    }),
  },
};
</script>
