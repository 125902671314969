<template>
  <div class="text-template-list">
    <v-container fluid grid-list-xl>
      <v-layout row wrap>
        <v-flex lg12>
          <Toolbar :handle-add="addHandler" :enable-refresh="true" :handle-refresh="refreshData" :title="$t('Issues')"/>

          <DataFilter :handle-filter="onSendFilter" :handle-reset="resetFilter">
            <IssueFilterForm :values="filters" ref="filterForm" slot="filter"/>
          </DataFilter>
          <br/>
          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page.sync="options.itemsPerPage"
            :loading="isLoading"
            :loading-text="$t('Loading...')"
            :options.sync="options"
            :server-items-length="totalItems"
            @update:options="onUpdateOptions"
            class="elevation-1"
            item-key="@id"
            v-model="selected"
          >
            <template #item.priority="{item}">
              {{ priorities[item.priority] ? priorities[item.priority] : "default" }}
            </template>
            <template #item.status="{item}">
              {{ status[item.status] ? status[item.status] : "new" }}
            </template>
            <template slot="item.meta" slot-scope="{ item }">
              <v-tooltip bottom v-if="item.assignedTo">
                <template v-slot:activator="{on, attrs}">
                  <v-icon v-bind="attrs" v-on="on" class="mr-2" small>mdi-account-alert</v-icon>
                </template>
                <span>
                  {{
                    getProfileItemBasics(getBackendUserItem(item.assignedTo, 'profile'), 'firstName') ?
                        getProfileItemBasics(getBackendUserItem(item.assignedTo, 'profile'), 'firstName') :
                    getBackendUserItem(item.assignedTo, 'email')
                  }}

                  {{
                    getProfileItemBasics(getBackendUserItem(item.assignedTo, 'profile'), 'lastName') ?
                        getProfileItemBasics(getBackendUserItem(item.assignedTo, 'profile'), 'lastName') : ''
                   }}
                </span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.start">
                <template v-slot:activator="{on, attrs}">
                  <v-icon v-bind="attrs" v-on="on" class="mr-1" small>mdi-calendar-start</v-icon>
                </template>
                <span>Beginnt: {{ formatDateTime(item.start) }}</span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.end">
                <template v-slot:activator="{on, attrs}">
                  <v-icon v-bind="attrs" v-on="on" class="mr-1" small>mdi-calendar-end</v-icon>
                </template>
                <span>Endet: {{ formatDateTime(item.end) }}</span>
              </v-tooltip>
            </template>
            <ActionCell
              :handle-edit="() => editHandler(props.item)"
              :handle-show="() => showHandler(props.item)"
              slot="item.action"
              slot-scope="props"
            ></ActionCell>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";
import ListMixin from "../../mixins/ListMixin";
import ActionCell from "../../components/ActionCell";
import DataFilter from "../../components/DataFilter";
import Toolbar from "../../components/Toolbar";
import IssueFilterForm from "../../components/issue/Filter";
import ProfileMixin from "@/mixins/ProfileMixin";
import BackendUserMixin from "@/mixins/BackendUserMixin";

export default {
  name: "IssueList",
  servicePrefix: "Issue",
  mixins: [ListMixin, ProfileMixin, BackendUserMixin],
  components: {
    Toolbar,
    ActionCell,
    DataFilter,
    IssueFilterForm,
  },
  data() {
    return {
      headers: [
        {text: this.$i18n.t("title"), value: "title", align: "start", width: "50%"},
        {text: this.$i18n.t("status"), value: "status"},
        {text: this.$i18n.t("priority"), value: "priority"},
        {
          text: "Meta",
          value: "meta",
          sortable: false,
          align: 'end',
        },
        {
          text: this.$i18n.t("Actions"),
          value: "action",
          sortable: false,
          align: 'end',
        },
      ],
      priorities: {
        10: this.$t("Low"),
        50: this.$t("Medium"),
        100: this.$t("High"),
      },
      status: {
        'new': this.$t("New"),
        'inProgress': this.$t("In progress"),
        'feedback': this.$t("Feedback"),
        'done': this.$t("Done"),
      },
      selected: [],
      filters: {
        status: ["new", "inProgress", "feedback"],
        priority: [],
      },
    };
  },
  computed: {
    ...mapGetters("issue", {
      items: "list",
    }),
    ...mapFields("issue", {
      deletedItem: "deleted",
      error: "error",
      isLoading: "isLoading",
      resetList: "resetList",
      totalItems: "totalItems",
      view: "view",
    })
  },
  methods: {
    log(message) {
      console.log(message);
    },
    ...mapActions("issue", {
      getPage: "fetchAll",
      deleteItem: "del",
      clearIssueData: 'resetAllData',
    }),
    resetFilter() {
      this.filters = {
        profile: {},
      };
    },
    refreshData(){
      this.clearIssueData();
      this.onSendFilter();
    }
  },
};
</script>
